<template>
  <div class="modal-overlay" @click.self="handleCloseModal">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
        <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
      </div>
      <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
        <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Añade votos</p>
        <p class="text-tp-yellow font-semibold px-6">Usa tu voto gratis o añade más votos a la canción: <br> <span class="underline">{{ songSelectedInfo.title }} - {{ songSelectedInfo.artist }}</span></p>
        <p class="text-tp-yellow font-semibold px-6" v-if="delayedFreeBoost">Investiga un poco la aplicación antes de desbloquear tu voto gratis</p>
        <div class="flex flex-col gap-4">
        <div class="flex gap-4 w-full px-6">
          <RankingAction :action="'FreeVote'" :availableFreeBoost="availableFreeBoost" @onClickFreeVote="emitFreeVote"/>
          <RankingAction :action="'Boost'" @onClickBoost="emitBoost"/>
        </div>
        <div class="px-6">
          <div class="flex gap-2 p-2 bg-tp-black border-2 border-tp-yellow rounded-xl text-sm">
              <input 
                  type="text" 
                  class=" bg-tp-black text-tp-yellow border-none outline-none px-2 py-1 w-full" 
                  placeholder="Código promocional"
                  v-model="promocode"               
              />
              <Button 
                  class="bg-tp-yellow text-tp-black px-4 py-2 rounded-md font-bold"
                  @click="emitApplyPromocode"
                  :disabled="!promocode" 
              >
                  Aplicar
              </Button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/black-close-icon.png';
import info from '@/assets/images/info-icon.png';
import RankingAction from './RankingAction.vue';
import { toRefs, ref } from 'vue';

export default {
  name: 'RankingBoostModal',
  components: {
    RankingAction
  },
  props: {
    availableFreeBoost: Boolean,
    songSelectedInfo: Object,
    delayedFreeBoost: Boolean
  },
  setup(props, { emit }) {
    const { availableFreeBoost } = toRefs(props);

    const promocode = ref(null);

    const handleCloseModal = () => {
      emit('onCloseModal');
    }

    const emitFreeVote = () => {
      console.log(availableFreeBoost);
      emit('onClickFreeVote');
    }

    const emitBoost = () => {
      emit('onClickBoost');
    }

    const emitApplyPromocode = () => {
      console.log("emitting")
      emit('onClickPromocode', promocode.value);
    }

    return {
      close,
      info,
      handleCloseModal,
      emitFreeVote,
      emitBoost,
      promocode,
      emitApplyPromocode
    }
  }
};
</script>
